<template>
    <van-empty image="search" v-if="linePointList.length == 0 && load" description="暂无数据" />
    <div class="point-container" v-else>
        <div
            v-for="(item, index) in linePointList"
            :key="index"
            class="point-item"
        >
            <van-cell-group>
                <van-cell
                    :title="item.lpTitle"
                    :value="item.lpAddress"
                    :label="item.pointTime"
                    value-class="point-list-item"
                />
            </van-cell-group>
        </div>
    </div>
    
</template>

<script>
import { getMyPunch } from "@/service";
export default {
    data() {
        return {
            load: false,
            linePointList: [],
        };
    },
    components: {},
    mounted() {
        this.getMyPunch();
    },
    methods: {
        async getMyPunch() {
            const params = {
                pageNo: 1,
                pageSize: 1000,
                commonId: "",
            };
            const { data } = await getMyPunch(params);
            this.load = true;
            this.linePointList = data ? data.list : [];
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin";
@import "@/style/_varibles";

.point-container {
    padding: 0 20px;
    @include wh(100%, auto);
    display: flex;
    flex-direction: column;
    .clock-info {
        @include sc(16px, $--color-text-secondary);
        line-height: 24px;
    }
    .point-list-item {
        font-size: 24px;
    }
}
</style>